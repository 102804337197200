// import React from "react";
import React, { useState, useEffect, useRef } from "react";
// react-router-domのインポートを追加
import { BrowserRouter } from "react-router-dom";

// cssインポート
import "./css/normalize.css";
import "./css/output.css";
import "./css/Index.css";
import "./css/mv.css";
import "./css/office.css";
import "./css/scenery.css";
import "./css/footer.css";

import Header from "./components/Header";
import Worklearn from "./components/Worklearn";
import Scenery from "./components/Scenery";
import Info from "./components/Info";
import Interview from "./components/Interview";
import AnimationTrigger from "./components/AnimationTrigger";
import Footer from "./components/Footer";

export const App = () => {
  // マウスが動いたらの処理
  const [mouseX, setMouseX] = useState(0);
  const [mouseY, setMouseY] = useState(0);

  useEffect(() => {
    window.addEventListener("load", function (event) {
      if (window.innerWidth > 400) {
        const mouseMoveListener = (event) => {
          setMouseX(event.clientX);
          setMouseY(event.clientY);
        };

        window.addEventListener("mousemove", mouseMoveListener);

        return () => {
          window.removeEventListener("mousemove", mouseMoveListener);
        };
      }
    });
  }, []);

  //   初回だけ
  const [bool, setBool] = useState(false);
  const renderFlgRef = useRef(false);

  // 初回レンダリングのみ実行
  useEffect(() => {
    renderFlgRef.current = true;
  }, []);

  // アクセス回数判定
  useEffect(() => {
    if (renderFlgRef.current) {
      // var access = localStorage.getItem("Access");
      // 初回かどうか
      if (localStorage.getItem("Access") == null) {
        localStorage.setItem("Access", "値入れました");
        setBool(true);
        // console.log("一回目");
      } else {
        setBool(false);
        // console.log("2回目以降");
      }
    }
  }, []);

  // 表示非表示,クラス替え
  const [visibleWork, setVisibleWork] = useState(null);
  // const [visibleScenery, setVisibleScenery] = useState(null);
  const [visibleInfo, setVisibleInfo] = useState(null);
  const [visibleInterview, setVisibleInterview] = useState(null);
  // const [visibleOffice, setVisibleOffice] = useState(null);

  return (
    <BrowserRouter>
      <div>
        <div
          className="pointer"
          style={{ transform: `translate(${mouseX}px, ${mouseY}px)` }}
        ></div>
        <div
          className="pointer is-small"
          style={{ transform: `translate(${mouseX}px, ${mouseY}px)` }}
        ></div>


        <div className={bool ? "zoom" : "zoomTrigger"}>
          <div className="wrapper">
            <Header></Header>
            <main id="page-wrap">
              <section id="mv">
                <div className="mv-wrap">
                  <div className="mv-contents">
                    <div className="mv-container">
                      <div className="catch-copy">
                        <h3 className="text-white md:text-4xl sm:text-2xl">
                          Your Mission
                        </h3>
                        <h1>
                          <div className="titles">
                            <span className="text-white robotoSlab">WORK</span>
                            <div className="bg_red"></div>
                          </div>
                          <span className="text-white">×</span>
                          <div className="titles">
                            <span className="text-white">LEARN</span>
                            <div className="bg_blue"></div>
                          </div>
                        </h1>
                      </div>
                      <div className="description">
                        <p>
                          株式会社FEDELTAは
                          <br />
                          <span className="red_word">ハタラク</span>
                          <span className="blue_word">マナブ</span>
                          <br />
                          をコンセプトに成長できる環境をご用意しております。
                          <br />
                        </p>

                        <p>
                          ご自身で身につけた技術だからこそ一生もののスキルになる！
                          <br />
                          仲間と共にそんな環境で一緒に働きませんか？
                        </p>
                        <div className="entry-button">
                          <a
                            href="https://doda.jp/DodaFront/View/JobSearchDetail/j_jid__3009605158/-fm__list/-tp__1/-mtmd__0/?from=list_browsed&usrclk_searchListCassette=PC-logoutJobSearchList_kyujinSearchResultCassetteArea_pos-1_size-E_companyNameLink"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            ENTRY
                          </a>
                        </div>
                      </div>
                      {/* エントリーボタン */}
                    </div>

                    <div className="single-scroll-down-animation animation">
                      <div className="scroll-contents">
                        <p>Scroll</p>
                        <div className="scroll-down"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/* ===================
            WORK＆LEARNエリア
          =================== */}
              <AnimationTrigger animation="animate-slide-top">
                <section id="work-learn" className="relative">
                  <div className="outer-wrap">
                    {/* 共通コンポーネント */}
                    <div className="section-container">
                      <p>仕事と研修について</p>
                      <h3 className="xl:text-xl md:text-lg">About W&L</h3>
                    </div>

                    <div className="lead-wrap">
                      <AnimationTrigger animation="animate-text-gray">
                        <p>
                          {/* FEDELTAが考えるキャリアパスは3つのステップとなります。 */}
                          <span className="section_heading red_word">
                            FEDELTAのキャリアパスは3つのステップ！
                          </span>
                          <br />
                          <span className="section_number">01.</span>
                          仕事と研修を両立する
                          <br />
                          <span className="section_number">02.</span>
                          研修が終わり、Webの仕事をスタートする
                          <br />
                          <span className="section_number">03.</span>
                          実務経験を身に付けて社内開発担当やフリーランスとして活躍する
                        </p>
                      </AnimationTrigger>
                      <AnimationTrigger animation="animate-text-gray">
                        <p>
                          ハタラクを通して、
                          <br />
                          未経験からWeb業界にチャレンジするための必要要素の一つ、
                          <br />
                          人間力 をさらに育んでいきたいと考えています。
                        </p>
                      </AnimationTrigger>

                      <AnimationTrigger animation="animate-text-gray">
                        <p>
                          FEDELTAが考える人間力とは、
                          <br />
                          『誰に対しても誠実であること』
                          <br />
                          『論理的な思考を持って考え抜くこと』
                          <br />
                          『協調性を持ってチームと共に成長できること』
                        </p>
                      </AnimationTrigger>
                      <AnimationTrigger animation="animate-text-gray">
                        <p>
                          FEDELTAが掲げる
                          <span className="blue_word">『可能性の最大化』</span>
                          を達成するために、
                          <br />
                          会社一丸となってその可能性を見つけ出し、共に成長していきましょう。
                        </p>
                      </AnimationTrigger>
                    </div>
                  </div>

                  {visibleWork && <Worklearn />}
                  <div
                    className={
                      visibleWork
                        ? "absolute animate-scale-out-center "
                        : "trigger-wrap"
                    }
                  >
                    <AnimationTrigger animation="animate-slide-top">
                      <div className="trigger-container">
                        {/* 背景入れる */}
                        <div className="trigger-lead">
                          <p className="mb-3 font-bold sm:mb-0">仕事と研修</p>
                          <h4 className="font-black tracking-wider roboto">
                            WORK & LEARN
                          </h4>
                        </div>

                        <div
                          className="trigger-btn"
                          onClick={() => {
                            setVisibleWork(visibleWork + 1);
                            // classToggle();
                          }}
                        >
                          <button className="view-button red">
                            <p>
                              {visibleWork ? "Waiting" : "More"}
                              <i className="ml-2 bi bi-arrow-right"></i>
                            </p>
                          </button>
                        </div>
                      </div>
                    </AnimationTrigger>
                  </div>
                </section>
              </AnimationTrigger>

              {/* ===================
          infoエリア
          =================== */}
              <AnimationTrigger
                animation="animate-slide-top"
                rootMargin="50px"
                triggerOnce
              >
                <section id="info" className="relative">
                  <div className="outer-wrap">
                    {/* 共通コンポーネント */}
                    <div className="section-container">
                      <p>会社概要</p>
                      <h3 className="xl:text-xl md:text-lg">FEDELTA INFO</h3>
                    </div>

                    <div className="lead-wrap">
                      <AnimationTrigger animation="animate-text-gray">
                        <h3>「どのような方を求めているか」</h3>
                      </AnimationTrigger>
                      <AnimationTrigger animation="animate-text-gray">
                        <p>
                          FEDELTAはこんな方と一緒に仕事をしたいと考えています。
                          <br />
                          ①事業内容や企業ビジョンに共感してくれる方
                          <br />
                          ②目標に向けて協力的に仕事を進めることができる方
                          <br />
                          ③前向きな考え方やモチベーションを維持できる方
                        </p>
                      </AnimationTrigger>
                      <AnimationTrigger animation="animate-text-gray">
                        <p>
                          未経験でチャレンジができるからこそ、
                          その方のご経験だけでなく、未来を一緒に考えてくれる方を歓迎したいと思っています。
                        </p>
                      </AnimationTrigger>
                    </div>
                  </div>

                  {visibleInfo && <Info />}
                  <div
                    className={
                      visibleInfo
                        ? "absolute animate-scale-out-center "
                        : "trigger-wrap"
                    }
                  >
                    <AnimationTrigger animation="animate-slide-top">
                      <div className="trigger-container">
                        {/* 背景入れる */}
                        <div className="trigger-lead">
                          <p className="mb-3 text-base font-bold sm:mb-0">
                            会社概要
                          </p>
                          <h4 className="text-2xl font-black tracking-wider roboto">
                            INFOMATION
                          </h4>
                        </div>
                        <div
                          className="trigger-btn"
                          onClick={() => {
                            setVisibleInfo(visibleInfo + 1);
                          }}
                        >
                          <button className="view-button blue">
                            <p>
                              {visibleInfo ? "Waiting" : "More"}
                              <i className="ml-2 bi bi-arrow-right"></i>
                            </p>
                          </button>
                        </div>
                      </div>
                    </AnimationTrigger>
                  </div>
                </section>
              </AnimationTrigger>
              {/* インタビューエリア */}
              <AnimationTrigger animation="animate-slide-top">
                <section id="interview" className="relative">
                  <div className="outer-wrap">
                    {/* 共通コンポーネント */}
                    <div className="section-container">
                      <p>社員インタビュー</p>
                      <h3 className="xl:text-xl md:text-lg">OUR INTERVIEW</h3>
                    </div>

                    <div className="lead-wrap">
                      <AnimationTrigger animation="animate-text-gray">
                        <p>
                          先輩社員のインタビューを通じて、生の声を聞いてみましょう。
                        </p>
                      </AnimationTrigger>
                      <AnimationTrigger animation="animate-text-gray">
                        <p>
                          質問内容はこちら
                          <br />
                          1.自己紹介
                          <br />
                          2.FEDELTAはどんな会社？
                          <br />
                          3.入社して良かったと思えること
                          <br />
                          4.これから入社するあなたに一言！
                        </p>
                      </AnimationTrigger>
                    </div>
                  </div>

                  {visibleInterview && <Interview />}
                  <div
                    className={
                      visibleInterview
                        ? "absolute animate-scale-out-center "
                        : "trigger-wrap"
                    }
                  >
                    <AnimationTrigger animation="animate-slide-top">
                      <div className="trigger-container">
                        {/* 背景入れる */}
                        <div className="trigger-lead">
                          <p className="mb-3 text-base font-bold sm:mb-0">
                            インタビュー
                          </p>
                          <h4 className="text-2xl font-black tracking-wider roboto">
                            INTERVIEW
                          </h4>
                        </div>
                        <div
                          className="trigger-btn"
                          onClick={() => {
                            setVisibleInterview(visibleInterview + 1);
                          }}
                        >
                          <button className="view-button red">
                            <p>
                              {visibleInterview ? "Waiting" : "More"}
                              <i className="ml-2 bi bi-arrow-right"></i>
                            </p>
                          </button>
                        </div>
                      </div>
                    </AnimationTrigger>
                  </div>
                </section>
              </AnimationTrigger>

              {/* オフィス紹介エリア */}
              <AnimationTrigger animation="animate-slide-top">
                <section id="office" className="relative">
                  <div className="outer-wrap">
                    {/* 共通コンポーネント */}
                    <div className="section-container">
                      <p>オフィス紹介</p>
                      <h3 className="xl:text-xl md:text-lg">OFFICE</h3>
                    </div>
                  </div>
                  <Scenery />
                </section>
              </AnimationTrigger>

              {/* ===================
            sceneryエリア
          =================== */}
              {/* <section id="scenery" className="relative">
                <AnimationTrigger animation="animate-slide-top">
                  <Scenery />
                </AnimationTrigger>
              </section> */}
            </main>
          </div>
        </div>

        <Footer></Footer>
      </div>
    </BrowserRouter>
  );
};

export default App;
