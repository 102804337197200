// header.js

import React, { useState } from "react";

// import { Link, Routes, Route, NavLink } from "react-router-dom";

// 画像import
import Logo from "../images/recruit-logo.png";
import "../css/header.css";

const Header = () => {
  const [active, setActive] = useState(true);
  const classToggle = () => {
    setActive(!active);
  };
  return (
    <header>
      <div className="header-wrap">
        <div className="sp-flex-page">
          <div>
            <img src={Logo} alt="fedelta_会社ロゴ" />
          </div>
          <button
            onClick={classToggle}
            className={active ? "menu-trigger" : "menu-trigger active"}
            id="menu"
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
        <div
          onClick={classToggle}
          className={active ? "header-container" : "header-container active"}
        >
          <div className="mb-10 logo pc-page">
            <img src={Logo} alt="fedelta_会社ロゴ" />
          </div>
          {/* メニュー表示 */}
          <div className="open-menu-container">
            <ul className="menu-list">
              <li>
                <a href="/">
                  <span>採用TOP</span>
                  <i className="bi bi-chevron-double-right sp-page"></i>
                </a>
              </li>
              <li>
                <a href="#work-learn">
                  <span>仕事と研修</span>
                  <i className="bi bi-chevron-double-right sp-page"></i>
                </a>
              </li>
              <li>
                <a href="#info">
                  <span>会社情報</span>
                  <i className="bi bi-chevron-double-right sp-page"></i>
                </a>
              </li>
              <li>
                <a href="#interview">
                  <span>社員インタビュー</span>
                  <i className="bi bi-chevron-double-right sp-page"></i>
                </a>
              </li>
              <li>
                <a href="#office">
                  <span>オフィス紹介</span>
                  <i className="bi bi-chevron-double-right sp-page"></i>
                </a>
              </li>
            </ul>
            <div className="entry-button white sp-page">
              <a href="https://doda.jp/DodaFront/View/JobSearchDetail/j_jid__3009605158/-fm__list/-tp__1/-mtmd__0/?from=list_browsed&usrclk_searchListCassette=PC-logoutJobSearchList_kyujinSearchResultCassetteArea_pos-1_size-E_companyNameLink" target="_blank" rel="noopener noreferrer">
                ENTRY
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
