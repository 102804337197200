import React from "react";

// 画像import
import Logo from "../images/recruit-logo-white.png";
import "../css/footer.css";
const Footer = () => {
  return (
    <footer>
      <div className="footer-wrap">
        <div className="footer-container">
          <div className="left-wrap">
            <div className="logo-footer">
              <a href="/">
                <img src={Logo} alt="株式会社フェデルタ_ロゴ" />
              </a>
            </div>
            <ul className="sm:text-lg">
              <li>
                <a href="/">採用TOP</a>
              </li>
              <li>
                <a href="#work-learn">仕事と研修</a>
              </li>
              <li>
                <a href="#info">会社情報</a>
              </li>
            </ul>
            <ul className="sm:text-lg">
              <li>
                <a href="#interview">社員インタビュー</a>
              </li>
              <li>
                <a href="#office">オフィス紹介</a>
              </li>
            </ul>
            <div className="link-wrap">
              <a href="https://doda.jp/DodaFront/View/JobSearchDetail/j_jid__3009605158/-fm__list/-tp__1/-mtmd__0/?from=list_browsed&usrclk_searchListCassette=PC-logoutJobSearchList_kyujinSearchResultCassetteArea_pos-1_size-E_companyNameLink"
                target="_blank"
                rel="noopener noreferrer"
              >
                ENTRY<i className="bi bi-arrow-right-circle"></i>
              </a>
              <a href="https://fedelta.biz/">
                コーポレートサイト<i className="bi bi-arrow-right-circle"></i>
              </a>
              <a href="https://service.fedeltaonline.com/">
                サービスサイト<i className="bi bi-arrow-right-circle"></i>
              </a>
            </div>
          </div>
          <div className="right-wrap">
            <div className="access-wrap">
              <p className="mb-10 text-xl text-bold">アクセス</p>
              <address>
                〒104-0032
                <br />
                本社：東京都中央区八丁堀3丁目18-10
                <br />
                S-GATE FIT八丁堀9F
              </address>
              <p className="number">TEL：03-6275-2080</p>
            </div>
            <div className="sns-wrap">
              <a
                href="https://instagram.com/fedelta.official?igshid=YmMyMTA2M2Y="
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="bi bi-instagram"></i>
              </a>
              <a
                href="https://twitter.com/makinodx714?s=20"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="bi bi-twitter"></i>
              </a>
            </div>
            <div className="copyright">
              <small>©FEDELTA Co., Ltd</small>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
