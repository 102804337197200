import React from "react";

import "../css/info.css";

const mission = () => {
  return (
    <div className="info-container animate-fade-in">
      <div className="info-contents">
        <h4 className="animate-slide-in-bottom">
          <div className="info-title blue">
            <span className="sub">企業理念</span>
            <span className="main">「可能性の最大化」</span>
          </div>
          <span className="info_desc">
            自身の秘めた可能性を見出し、 想像できなかった未来を創造する。
          </span>
        </h4>

        <div className="mission-wrap">
          <div className="mission-container white">
            <div className="mission-title">
              <h5>HONESTY</h5>
              <span>誠実であること</span>
            </div>
            <div className="mission-group">
              <p>
                <i className="bi bi-check"></i>真摯に業務に取り組むこと
              </p>
              <p>
                <i className="bi bi-check"></i>小さな約束でも守ること
              </p>
              <p>
                <i className="bi bi-check"></i>報連相を徹底すること
              </p>
              <p>
                <i className="bi bi-check"></i>時間を守ること
              </p>
              <p>
                <i className="bi bi-check"></i>悩みを打ち明けること
              </p>
            </div>
          </div>
          <div className="mission-container white">
            <div className="mission-title">
              <h5>YOUR BEST</h5>
              <span>ベストを尽くすこと</span>
            </div>
            <div className="mission-group">
              <p>
                <i className="bi bi-check"></i>自身最大のパフォーマンスを
              </p>
              <p>
                <i className="bi bi-check"></i>自分の強みを磨き上げる
              </p>
              <p>
                <i className="bi bi-check"></i>事前準備を怠らない
              </p>
              <p>
                <i className="bi bi-check"></i>スピード感を求めて実行する
              </p>
              <p>
                <i className="bi bi-check"></i>こだわって結果を求める
              </p>
            </div>
          </div>
          <div className="mission-container white">
            <div className="mission-title">
              <h5>With Why</h5>
              <span>考え抜くこと</span>
            </div>
            <div className="mission-group">
              <p>
                <i className="bi bi-check"></i>目的思考で行動する
              </p>
              <p>
                <i className="bi bi-check"></i>自身で「なぜ」を常に考える
              </p>
              <p>
                <i className="bi bi-check"></i>データやエビデンスに基づく
              </p>
              <p>
                <i className="bi bi-check"></i>自分なりの意見を発信する
              </p>
              <p>
                <i className="bi bi-check"></i>倫理的な思想や
                曖昧さを排除した意思
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default mission;
