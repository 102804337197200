import React from "react";
import Work from "../images/worklearn/work.jpeg";
import Learn from "../images/worklearn/learn.jpeg";
import AnimationTrigger from "../components/AnimationTrigger";

import "../css/wl.css";

const Worklearn = () => {
  return (
    <>
      <div className="wrap animate-scale-in-center">
        <div className="wl-wrap">
          {/* 仕事内容 */}
          <div className="wl-container">
            <div className="img-group">
              <img src={Work} alt="仕事をしている風景" />
              <h4 className="tracking-wider wl-title robotoSlab animate-fade-in-fwd">
                WORK
              </h4>
            </div>
            <AnimationTrigger
              animation="animate-slide-top-slow"
              rootMargin="-50px"
              triggerOnce
            >
              <div className="inside-contents">
                <h4 className="">仕事内容</h4>
                <div className="top-group">
                  <h5 className="lead">研修後のポジション</h5>
                  <div className="content-list">
                    <div>
                      <p>
                        <i className="bi bi-check2-square"></i>Webディレクター
                      </p>
                      <p>
                        <i className="bi bi-check2-square"></i>Webデザイナー
                      </p>
                    </div>
                    <div>
                      <p>
                        <i className="bi bi-check2-square"></i>Webエンジニア
                      </p>
                      <p>
                        <i className="bi bi-check2-square"></i>Webコーダー
                      </p>
                    </div>
                    <div>
                      <p>
                        <i className="bi bi-check2-square"></i>プログラマー
                      </p>
                      <p>
                        <i className="bi bi-check2-square"></i>
                        その他多数あり
                      </p>
                    </div>
                  </div>
                </div>
                <div className="bottom-container">
                  <h5 className="lead">研修中の仕事内容</h5>
                  <div className="content-list">
                    <div>
                      <p>
                        <i className="bi bi-check2-square"></i>
                        販売業務（携帯電話や家電製品、日用品など）
                      </p>
                      <p>
                        <i className="bi bi-check2-square"></i>
                        コールセンター業務
                      </p>
                    </div>
                    <div>
                      <p>
                        <i className="bi bi-check2-square"></i>
                        営業職/事務職
                      </p>
                      <p>
                        <i className="bi bi-check2-square"></i>
                        その他多数あり
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </AnimationTrigger>
          </div>
          {/* 研修内容 */}
          <div className="wl-container"> 
            <div className="img-group bg-dark">
              <img src={Learn} alt="研修をしている男性" />
              <h4 className="tracking-wider wl-title roboto animate-fade-in-fwd">
                LEARN
              </h4>
            </div>
            <AnimationTrigger
              animation="animate-slide-top-slow"
              rootMargin="-50px"
              triggerOnce
            >
              <div className="inside-contents">
                <h4>研修内容</h4>
                <div className="top-group">
                  <h5 className="lead">フロントエンドコース</h5>
                  <div className="content-list">
                    <div>
                      <p>
                        <i className="bi bi-check2-square"></i>HTML/CSSの基礎
                      </p>
                      <p>
                        <i className="bi bi-check2-square"></i>PCページ制作
                      </p>
                    </div>
                    <div>
                      <p>
                        <i className="bi bi-check2-square"></i>スマホページ制作
                      </p>
                      <p>
                        <i className="bi bi-check2-square"></i>JavaScript基礎
                      </p>
                    </div>
                    <div>
                      <p>
                        <i className="bi bi-check2-square"></i>jQuery基礎
                      </p>
                      <p>
                        <i className="bi bi-check2-square"></i>HP制作実践課題
                      </p>
                    </div>
                  </div>
                </div>
                <div className="bottom-container">
                  <h5 className="lead">サーバーサイドコース</h5>
                  <div className="content-list">
                    <p>
                      <i className="bi bi-check2-square"></i>
                      環境構築(XAMPP,MAMP)
                    </p>
                    <p>
                      <i className="bi bi-check2-square"></i>コマンドライン
                    </p>
                    <p>
                      <i className="bi bi-check2-square"></i>PHP基礎
                    </p>
                    <p>
                      <i className="bi bi-check2-square"></i>データベース基礎
                    </p>
                    <p>
                      <i className="bi bi-check2-square"></i>
                      フレームワーク(Laravel)基礎
                    </p>
                    <p>
                      <i className="bi bi-check2-square"></i>Webアプリ開発課題
                    </p>
                  </div>
                </div>
              </div>
            </AnimationTrigger>
          </div>
        </div>
      </div>
    </>
  );
};

export default Worklearn;
