import React from "react";
import Scenery01 from "../images/scenery/slide_01.jpg";
import Scenery01_sp from "../images/scenery/sp/sp-slide_01.jpg";
import Scenery02 from "../images/scenery/slide_02.jpg";
import Scenery02_sp from "../images/scenery/sp/sp-slide_02.jpg";
import Scenery03 from "../images/scenery/slide_03.jpg";
import Scenery03_sp from "../images/scenery/sp/sp-slide_03.jpg";
import Scenery04 from "../images/scenery/slide_04.jpg";
import Scenery04_sp from "../images/scenery/sp/sp-slide_04.jpg";
import Scenery05 from "../images/scenery/slide_05.jpg";
import Scenery05_sp from "../images/scenery/sp/sp-slide_05.jpg";
import Scenery06 from "../images/scenery/slide_06.jpg";
import Scenery06_sp from "../images/scenery/sp/sp-slide_06.jpg";
import Scenery07 from "../images/scenery/slide_07.jpg";
import Scenery07_sp from "../images/scenery/sp/sp-slide_07.jpg";
import Scenery08 from "../images/scenery/slide_08.jpg";
import Scenery08_sp from "../images/scenery/sp/sp-slide_08.jpg";
const Scenery = () => {
  return (
    <>
      <div className="wrap scenery">
        {/* 上側 */}
        <div className="top-group loop-container">
          <div className="loop_wrap">
            <ul>
              <li>
                <img
                  loading="lazy"
                  className="pc"
                  src={Scenery01}
                  alt="社内風景01"
                />
                <img
                  loading="lazy"
                  className="sp"
                  src={Scenery01_sp}
                  alt="社内風景01"
                />
              </li>
              <li>
                <img
                  loading="lazy"
                  className="pc"
                  src={Scenery02}
                  alt="社内風景02"
                />
                <img
                  loading="lazy"
                  className="sp"
                  src={Scenery02_sp}
                  alt="社内風景02"
                />
              </li>
              <li>
                <img
                  loading="lazy"
                  className="pc"
                  src={Scenery03}
                  alt="社内風景03"
                />
                <img
                  loading="lazy"
                  className="sp"
                  src={Scenery03_sp}
                  alt="社内風景03"
                />
              </li>
              <li>
                <img
                  loading="lazy"
                  className="pc"
                  src={Scenery04}
                  alt="社内風景04"
                />
                <img
                  loading="lazy"
                  className="sp"
                  src={Scenery04_sp}
                  alt="社内風景04"
                />
              </li>
            </ul>
            <ul>
              <li>
                <img
                  loading="lazy"
                  className="pc"
                  src={Scenery01}
                  alt="社内風景01"
                />
                <img
                  loading="lazy"
                  className="sp"
                  src={Scenery01_sp}
                  alt="社内風景01"
                />
              </li>
              <li>
                <img
                  loading="lazy"
                  className="pc"
                  src={Scenery02}
                  alt="社内風景02"
                />
                <img
                  loading="lazy"
                  className="sp"
                  src={Scenery02_sp}
                  alt="社内風景02"
                />
              </li>
              <li>
                <img
                  loading="lazy"
                  className="pc"
                  src={Scenery03}
                  alt="社内風景03"
                />
                <img
                  loading="lazy"
                  className="sp"
                  src={Scenery03_sp}
                  alt="社内風景03"
                />
              </li>
              <li>
                <img
                  loading="lazy"
                  className="pc"
                  src={Scenery04}
                  alt="社内風景04"
                />
                <img
                  loading="lazy"
                  className="sp"
                  src={Scenery04_sp}
                  alt="社内風景04"
                />
              </li>
            </ul>
          </div>
        </div>
        {/* 下側 */}
        <div className="bottom-group loop-container">
          <div className="loop_wrap reverse">
            <ul>
              <li>
                <img
                  loading="lazy"
                  className="pc"
                  src={Scenery05}
                  alt="社内風景05"
                />
                <img
                  loading="lazy"
                  className="sp"
                  src={Scenery05_sp}
                  alt="社内風景05"
                />
              </li>
              <li>
                <img
                  loading="lazy"
                  className="pc"
                  src={Scenery06}
                  alt="社内風景06"
                />
                <img
                  loading="lazy"
                  className="sp"
                  src={Scenery06_sp}
                  alt="社内風景06"
                />
              </li>
              <li>
                <img
                  loading="lazy"
                  className="pc"
                  src={Scenery07}
                  alt="社内風景07"
                />
                <img
                  loading="lazy"
                  className="sp"
                  src={Scenery07_sp}
                  alt="社内風景07"
                />
              </li>
              <li>
                <img
                  loading="lazy"
                  className="pc"
                  src={Scenery08}
                  alt="社内風景08"
                />
                <img
                  loading="lazy"
                  className="sp"
                  src={Scenery08_sp}
                  alt="社内風景08"
                />
              </li>
            </ul>
            <ul>
              <li>
                <img
                  loading="lazy"
                  className="pc"
                  src={Scenery05}
                  alt="社内風景01"
                />
                <img
                  loading="lazy"
                  className="sp"
                  src={Scenery05_sp}
                  alt="社内風景01"
                />
              </li>
              <li>
                <img
                  loading="lazy"
                  className="pc"
                  src={Scenery06}
                  alt="社内風景02"
                />
                <img
                  loading="lazy"
                  className="sp"
                  src={Scenery06_sp}
                  alt="社内風景02"
                />
              </li>
              <li>
                <img
                  loading="lazy"
                  className="pc"
                  src={Scenery07}
                  alt="社内風景03"
                />
                <img
                  loading="lazy"
                  className="sp"
                  src={Scenery07_sp}
                  alt="社内風景03"
                />
              </li>
              <li>
                <img
                  loading="lazy"
                  className="pc"
                  src={Scenery08}
                  alt="社内風景04"
                />
                <img
                  loading="lazy"
                  className="sp"
                  src={Scenery08_sp}
                  alt="社内風景04"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Scenery;
