import React, { useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
// swiperで用意されているデフォルトののスタイル
import "swiper/swiper.min.css";
import Staff1 from "../images/interview/staff1.jpg";
import Staff2 from "../images/interview/staff2.jpg";
import Staff3 from "../images/interview/staff3.jpg";
import Staff4 from "../images/interview/staff4.jpg";
import Swipe from "../images/swipe.svg";
import { EffectCards } from "swiper";
import "../css/interview.css";

const Interview = () => {
  /* state変数を定義 */
  const [active, setActive] = useState(false);

  const classToggle = () => {
    setActive(!active);
  };

  return (
    <>
      <div className="wrap animate-scale-in-center">
        <Swiper
          effect={"cards"}
          grabCursor={true}
          modules={[EffectCards]}
          className={active ? "mySwiper active" : "mySwiper"}
        >
          {/* 1人目 */}
          <SwiperSlide>
            <div className="interview-container">
              <div
                className={
                  active
                    ? "interview-left-contents active"
                    : "interview-left-contents"
                }
              >
                <div className="interview-img bg-img">
                  <img src={Staff1} alt="インタビュー画像1" />
                </div>
                <div className="interview-info">
                  <div className="profile">
                    <p>
                      T.YONESHIMA
                      <span>エンジニア</span>
                    </p>
                    <p>入社１年目</p>
                  </div>
                  <div className="view-more">
                    <button onClick={classToggle}>
                      <p>
                        <p>{active ? "CLOSE" : "INTERVIEW"}</p>
                      </p>
                      <p>
                        {active ? (
                          <i className="bi bi-arrow-down-circle"></i>
                        ) : (
                          <i className="bi bi-arrow-right-circle"></i>
                        )}
                      </p>
                    </button>
                  </div>
                </div>
              </div>
              <div
                className={
                  active
                    ? "interview-right-contents active"
                    : "interview-right-contents"
                }
              >
                <div className="interview-wrapper">
                  <div className="question">
                    <h4>自己紹介</h4>
                    <p>
                      社内エンジニアのヨネです。 前職は販売員をしていました。
                      未経験でスタートしましたが、研修を終わらせ、社内エンジニアとして頑張っています！
                    </p>
                  </div>
                  <div className="question">
                    <h4>FEDELTAはどんな会社？</h4>
                    <p>
                      それぞれ色んな選択肢があって、多方面で個人を生かせる環境だと思います！
                      研修を終わらせて、社内エンジニアとしてデビューさせていただきました！
                    </p>
                  </div>
                  <div className="question">
                    <h4>入社して良かったと思えること</h4>
                    <p>
                      しっかりとした研修制度と、現役の先輩エンジニアが研修講師として教えてくれる環境には安心感を感じます。
                      ただ教えるだけでなく、自分で理解できるようにヒントを踏まえて教えていただけるので自分の力になります。
                      簡単ではありませんでしたが、確実に自分の力になっているな、と感じています！
                    </p>
                  </div>
                  <div className="question">
                    <h4>これから入社するあなたに一言！</h4>
                    <p>
                      SEとしての成長環境はもちろん、努力次第で個人の新しい可能性を活かしてくれると思っています！
                      一緒に頑張りましょう♪
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          {/* 二人目 */}
          <SwiperSlide>
            <div className="interview-container">
              <div
                className={
                  active
                    ? "interview-left-contents active"
                    : "interview-left-contents"
                }
              >
                <div className="interview-img bg-img">
                  <img src={Staff2} alt="インタビュー画像2" />
                </div>
                <div className="interview-info">
                  <div className="profile">
                    <p>
                      R.KANEKO
                      <span>研修中</span>
                    </p>
                    <p>入社１年目</p>
                  </div>
                  <div className="view-more">
                    <button onClick={classToggle}>
                      <p>{active ? "CLOSE" : "INTERVIEW"}</p>
                      <p>
                        {active ? (
                          <i className="bi bi-arrow-down-circle"></i>
                        ) : (
                          <i className="bi bi-arrow-right-circle"></i>
                        )}
                      </p>
                    </button>
                  </div>
                </div>
              </div>
              <div
                className={
                  active
                    ? "interview-right-contents active"
                    : "interview-right-contents"
                }
              >
                <div className="interview-wrapper">
                  <div className="question">
                    <h4>自己紹介</h4>
                    <p>
                      研修生のかねこです。 前職は不動産業の受付をしていました。
                      ９月に未経験で入社させていただき、もう少しでIT業務にデビューする予定です！ITの現場に異動で不安もありますが、楽しみです！！
                    </p>
                  </div>
                  <div className="question">
                    <h4>FEDELTAはどんな会社？</h4>
                    <p>
                      働き方は客先常駐ですが、本社の方々も積極的に交流を持とうとしてくれるのが嬉しいですし、みんないい人ばかりなので楽しんで仕事してます！！
                    </p>
                  </div>
                  <div className="question">
                    <h4>入社して良かったと思えること</h4>
                    <p>
                      研修内容も定期的にアップデートされていて、知らなかった知識やスキルが身につくのを実感しています！
                      仕事と学習の両立は大変ですが、コツコツやればしっかりと次のステップへ進めるので楽しいです！
                    </p>
                  </div>
                  <div className="question">
                    <h4>これから入社するあなたに一言！</h4>
                    <p>
                      完全未経験の私でも、しっかりとやれば自分の身につくので、仕事と勉強を両立できる方にはとても良い環境だと思います！後輩ができるのが楽しみです！
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          {/* 3人目 */}
          <SwiperSlide>
            <div className="interview-container">
              <div
                className={
                  active
                    ? "interview-left-contents active"
                    : "interview-left-contents"
                }
              >
                <div className="interview-img bg-img">
                  <img src={Staff3} alt="インタビュー画像3" />
                </div>
                <div className="interview-info">
                  <div className="profile">
                    <p>
                      M.MIKAMI
                      <span>営業</span>
                    </p>
                    <p>入社2年目</p>
                  </div>
                  <div className="view-more">
                    <button onClick={classToggle}>
                      <p>
                        <p>{active ? "CLOSE" : "INTERVIEW"}</p>
                      </p>
                      <p>
                        {active ? (
                          <i className="bi bi-arrow-down-circle"></i>
                        ) : (
                          <i className="bi bi-arrow-right-circle"></i>
                        )}
                      </p>
                    </button>
                  </div>
                </div>
              </div>
              <div
                className={
                  active
                    ? "interview-right-contents active"
                    : "interview-right-contents"
                }
              >
                <div className="interview-wrapper">
                  <div className="question">
                    <h4>自己紹介</h4>
                    <p>
                      営業のみかみです。
                      前職はコールセンターの仕事をしていました。
                      ITへの業種希望で研修を最後までやりましたが、自分は営業がやりたい！と思い、現在は社内の営業チームでやってます！！
                    </p>
                  </div>
                  <div className="question">
                    <h4>FEDELTAはどんな会社？</h4>
                    <p>
                      SES事業もそうですが、最近ではRIZINのスポンサーになったり、ECの事業を開始したり、もし明確にやりたいことを提示することができればそれも実現してもらえる会社だと思っています！
                      なので、仮にSEを断念するという形になっても、色々な方から自身を生かす道を探してくれます。
                    </p>
                  </div>
                  <div className="question">
                    <h4>入社して良かったと思えること</h4>
                    <p>
                      客先常駐＆IT研修生として入社→現在は社内営業としてJoinして、任されることが多くなりましたが、上司の方がとても親身に話を聞いてくれたり、これから営業のスキルも絶対に必要なので、色んなことを学べる、チャレンジさせてくれるのがやりがいを感じます！
                    </p>
                  </div>
                  <div className="question">
                    <h4>これから入社するあなたに一言！</h4>
                    <p>
                      ITだけじゃない！色んな可能性を追いかけていきましょう！！
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          {/* 四人目 */}
          <SwiperSlide>
            <div className="interview-container">
              <div
                className={
                  active
                    ? "interview-left-contents active"
                    : "interview-left-contents"
                }
              >
                <div className="interview-img bg-img">
                  <img src={Staff4} alt="インタビュー画像4" />
                </div>
                <div className="interview-info">
                  <div className="profile">
                    <p>
                      Y.Ogura
                      <span>営業部長</span>
                    </p>
                    <p>創業メンバー</p>
                  </div>
                  <div className="view-more">
                    <button onClick={classToggle}>
                      <p>
                        <p>{active ? "CLOSE" : "INTERVIEW"}</p>
                      </p>
                      <p>
                        {active ? (
                          <i className="bi bi-arrow-down-circle"></i>
                        ) : (
                          <i className="bi bi-arrow-right-circle"></i>
                        )}
                      </p>
                    </button>
                  </div>
                </div>
              </div>
              <div
                className={
                  active
                    ? "interview-right-contents active"
                    : "interview-right-contents"
                }
              >
                <div className="interview-wrapper">
                  <div className="question">
                    <h4>自己紹介</h4>
                    <p>
                      創業メンバーの小倉です。 営業部長しています。
                      情熱を持って仕事をしています。
                    </p>
                  </div>
                  <div className="question">
                    <h4>FEDELTAはどんな会社？</h4>
                    <p>
                      立場によって見え方は違いますが、ここまで大きくなることは想像できませんでした。成長の証を実感できるいい会社だと思います。
                    </p>
                  </div>
                  <div className="question">
                    <h4>入社して良かったと思えること</h4>
                    <p>
                      会社に貢献をし続けて、それが自他共に認められる結果として反映された時。部下の成長が成果に繋がった時。今よりも会社が大きくなった時。みんなでしょうもない話ができる時。数え切れないです。笑
                    </p>
                  </div>
                  <div className="question">
                    <h4>これから入社するあなたに一言！</h4>
                    <p>
                      この２年で部長が５人増えました。
                      職種だけじゃなくて、上を狙ってほしいし、クリエイティブな人が増えてほしいです。
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
        <div className="swipe-img">
          <p>
            swipe left or right!
            <img src={Swipe} alt="スワイプアイコン" />
          </p>
        </div>
      </div>
    </>
  );
};

export default Interview;
