import React from "react";

import "../css/wl.css";

const Philosophy = () => {
  return (
    <div className="info-container animate-fade-in">
      <div className="info-contents">
        <h4 className="lg:text-4xl md:text-2xl sm:text-lg animate-slide-in-bottom">
          人は誰しもポテンシャルを持っている
        </h4>
        <p>
          社員全員が各々様々な可能性を持っている。
          <br />
          その可能性を見つけ出し、どう成長させていくか。
          <br />
          目標に向かって導いていく事が我々の仕事です。
        </p>
        <div className="philosophy-container"></div>
      </div>
    </div>
  );
};

export default Philosophy;
