import React from "react";

// react-router-domのインポートを追加
import { Routes, Route, NavLink } from "react-router-dom";

import Philosophy from "./Philosophy";
import Mission from "./Mission";
import TopInterview from "./TopInterview";
// import History from "./History";
import Welfare from "./Welfare";

import "../css/info.css";

const Info = () => {
  return (
    <>
      <div className="info-wrap animate-scale-in-center">
        {/* コンテンツ */}
        <div className="info-outer-container">
          <div className="info-menu">
            <h3 className="roboto">INFO MENU</h3>
            <br />

            <div className="link-menu">
              <NavLink activeClassName="active" to="/">
                <span>ミッション・ビジョン</span>
              </NavLink>
              {/* <br /> */}
              <NavLink activeClassName="active" to="/Welfare">
                <span>福利厚生</span>
              </NavLink>
              {/* <br /> */}
              <NavLink activeClassName="active" to="/TopInterview">
                <span>代表インタビュー</span>
              </NavLink>
            </div>
          </div>
          <Routes>
            {/* exactをつけると完全一致になります。Homeはexactをつけてあげます */}
            {/* <Route exact path="/" element={<TopInterview />} /> */}
            <Route path="/" element={<Mission />} />
            <Route path="/philosophy" element={<Philosophy />} />
            {/* <Route path="/history" element={<History />} /> */}
            <Route path="/Welfare" element={<Welfare />} />
            <Route path="/TopInterview" element={<TopInterview />} />
          </Routes>
        </div>
      </div>
    </>
  );
};

export default Info;
