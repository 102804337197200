import React from "react";

import Train from "../images/電車、駅のマーク.svg";
import Laptop from "../images/ノートPCアイコン.svg";
import Holiday from "../images/休日.svg";
import Grade from "../images/等級.svg";
import Child from "../images/育休.svg";
import Incentive from "../images/インセンティブ.svg";

import "../css/info.css";

const Welfare = () => {
  return (
    <div className="info-container animate-fade-in">
      <div className="info-contents">
        <h4 className="lg:text-4xl md:text-2xl sm:text-lg animate-slide-in-bottom">
          <div className="info-title blue">
            <span className="main">福利厚生</span>
          </div>
          <span className="info_desc">
            社員の皆様が働きやすい会社を作り上げることを約束します！
            <br />
            意見交換をして、より良い会社にしていきましょう！
          </span>
        </h4>

        <div className="welfare-wrap">
          <div className="mb-6 welfare-container white">
            <div className="welfare-group">
              <img src={Train} alt="通勤手当" />
              <p>通勤手当(上限4万円)</p>
            </div>
          </div>
          <div className="mb-6 welfare-container white">
            <div className="welfare-group">
              <img src={Laptop} alt="研修制度" />
              <p>研修制度(うぇぶくり)</p>
            </div>
          </div>
          <div className="mb-6 welfare-container white">
            <div className="welfare-group">
              <img src={Holiday} alt="年間休日数" />
              <p>年間休日数120日</p>
            </div>
          </div>
          <div className="welfare-container white">
            <div className="welfare-group">
              <img src={Grade} alt="等級制度" />
              <p>等級制度あり</p>
            </div>
          </div>
          <div className="welfare-container white">
            <div className="welfare-group">
              <img src={Child} alt="育休・産休（実績あり）" />
              <p>育休・産休（実績あり）</p>
            </div>
          </div>
          <div className="welfare-container white">
            <div className="welfare-group">
              <img src={Incentive} alt="インセンティブ制度" />
              <p>インセンティブ制度</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welfare;
