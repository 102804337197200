import React from "react";

import "../css/info.css";
import Ceoimage from "../images/ceo_recruit.jpg";

const TopInterview = () => {
  return (
    <div className="info-container animate-fade-in">
      <div className="info-contents">
        <h4 className="lg:text-4xl md:text-2xl sm:text-lg animate-slide-in-bottom">
          <div className="info-title blue">
            <span className="main">人は誰しもポテンシャルを持っている</span>
          </div>
        </h4>
        {/* 白背景 */}
        <div className="message-wrap">
          <div className="message-container">
            <p>
              弊社では、「人は誰しもポテンシャルを持っている」ということを考えます。
              <br />
              FEDELTA社員全員が、それぞれ様々な可能性を持っている。
              <br />
              その可能性を見つけ出し、どう成長させていくか。
              <br />
              働く事への考え方、行動の仕方、自身のキャリア目標設定、その為に必要な行動、
              <br />
              これらをアドバイスしながら目標に向かって、導いていく事が我々の仕事です。
            </p>
          </div>
          <div className="img-container">
            <img src={Ceoimage} alt="牧野社長" />
            <p>
              代表取締役社長
              <span>牧野 祐介</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopInterview;
